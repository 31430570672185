html {
    box-sizing: border-box;
    font-size: 62.5%;
    scroll-behavior: smooth;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

@media screen and (min-resolution: 120dpi) {
    body {zoom: 1.0;}
}

@font-face {
    font-family: 'CircularStd';
    src: url('/fonts/CircularStd-Book.woff2') format('woff2'),
        url('/fonts/CircularStd-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('/fonts/CircularStd-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

body {
    font-family: 'CircularStd', sans-serif;
    font-size: 1.5rem;
}

body,
html {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

#__next {
    height: 100%;
}

body {
    overflow: hidden;
}

#__next {
    overflow: auto;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'CircularStd', serif;
    margin: 0;
}

a {
    color: #0052b4;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.errorMessage {
    color: red;
    margin-top: 10px;
    font-size: 12px;
}

.cv-hide {
    display: none;
}

.cv-block {
    display: block;
}

hr {
    border: 0 none;
    height: 1px;
    background: #e3e4e8;
}

.tippy-theme-cv {
    background: #0a0e27;
    font-size: 13px;
    font-weight: bold;
}

.tippy-theme-cv .tippy-content {
    padding: 4px 9px;
}

.tippy-tooltip.tippy-theme-cv[data-placement^='top'] .tippy-arrow {
    border-top-color: #0a0e27;
    border-width: 4px 4px 0;
    margin: 0 5px;
    bottom: -4px;
}

.tippy-tooltip.tippy-theme-cv[data-placement^='bottom'] .tippy-arrow {
    border-bottom-color: #0a0e27;
    border-width: 0 4px 4px;
    margin: 0 5px;
    top: -4px;
}

.tippy-tooltip.tippy-theme-cv[data-placement^='left'] .tippy-arrow {
    border-left-color: #0a0e27;
    border-width: 4px 0 4px 4px;
    margin: 5px 0;
    right: -4px;
}

.tippy-tooltip.tippy-theme-cv[data-placement^='right'] .tippy-arrow {
    border-right-color: #0a0e27;
    border-width: 4px 4px 4px 0;
    margin: 5px 0;
    left: -4px;
}

.Toastify__toast {
    font-family: inherit;
}

.Toastify__toast--error {
    background: #d80027;
    border-radius: 6px;
    font-family: inherit;
}

.Toastify__toast--success {
    background: #03ce9c;
    border-radius: 6px;
    font-family: inherit;
}

.Toastify__toast.toast-event {
    min-height: 52px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7) !important;
}

@keyframes SlideUpFadeOut {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    to {
        visibility: hidden;
        transform: translate3d(0, -100px, 0);
        opacity: 0;
    }
}

@keyframes SlideRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.SlideRight {
    animation-name: SlideRight;
}

.SlideUpFadeOut {
    animation-name: SlideUpFadeOut;
}

#hubspot-messages-iframe-container {
    margin-bottom: 72px;
}
